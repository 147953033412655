<!-- eslint-disable no-tabs -->
<template>
  <div class="main">
    <v-layout :class="[$vuetify.display.mdAndUp ? 'full-page-desktop' : 'full-page-mobile px-4', 'd-flex align-center']">
      <v-row>
        <v-col
          xs="12"
          md="6"
          lg="6"
          cols="12"
          :style="[$vuetify.display.mdAndUp ? '' : 'height: 50vh']"
        >
          <div :class="[$vuetify.display.mdAndUp ? 'px-8' : '']">
            <div class="d-flex justify-center">
              <v-img src="/img/tayoimmomig/TAYO.svg" :max-width="getProperSize('300', '200')" />
            </div>
            <v-card-text :class="[$vuetify.display.smAndUp ? 'business-desc-text' : 'business-desc-text-mobile', 'white-text text-center mx-8']">
              {{ $t('tayo_immomig.tayo_description') }}
            </v-card-text>
          </div>
        </v-col>
        <v-col
          xs="12"
          md="6"
          lg="6"
          cols="12"
        >
          <div :class="[$vuetify.display.mdAndUp ? 'px-8' : '']">
            <div class="d-flex justify-center">
              <v-img src="/img/tayoimmomig/IMMOMIGSA.svg" :max-width="getProperSize('480', '300')" />
            </div>
            <v-card-text :class="[$vuetify.display.smAndUp ? 'business-desc-text' : 'business-desc-text-mobile', 'text-center mx-8 ']">
              {{ $t('tayo_immomig.immomig_description') }}
            </v-card-text>
          </div>
        </v-col>
      </v-row>
    </v-layout>

    <div class="identity-gradient" style="height: 50px;" />
    <v-container :class="[$vuetify.display.smAndUp ? '' : 'px-4', 'mt-12 pb-0']">
      <v-row justify="center" class="mt-8" style="background-color: #FFFFFF">
        <v-col
          xs="11"
          sm="8"
          md="8"
          lg="8"
          cols="12"
          class="justify-center"
        >
          <p class="text-center pb-2">
            <span class="emoji" />  <span class="gradient-text">{{ $t('tayo_immomig.partnership_announcement') }}</span>
          </p>
          <h1 class="text-center">
            {{ $t('tayo_immomig.title_one_stop_shop') }}
          </h1>
          <div class="wave mt-8" style="margin: 0 auto" />
          <h3 class="text-center mt-6">
            {{ $t('tayo_immomig.why_tayo_immomig') }}
          </h3>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="pt-0">
      <v-row justify="center" class="mt-8" style="background-color: #FFFFFF">
        <v-col
          xs="12"
          sm="10"
          md="10"
          lg="8"
          xl="4"
          cols="12"
        >
          <v-row>
            <v-col
              xs="12"
              sm="4"
              md="4"
              lg="3"
              cols="12"
            >
              <div class="d-flex justify-center">
                <v-img src="/img/tayoimmomig/tenant.svg" :height="getProperSize('200', '150')" width="220" />
              </div>
            </v-col>
            <v-col
              xs="12"
              sm="8"
              md="8"
              lg="9"
              cols="12"
            >
              <div xs12 md8 class="d-flex">
                <v-card flat tile>
                  <v-card-title :class="[$vuetify.display.smAndUp ? 'px-0' : 'text-center pt-0']">
                    {{ $t('tayo_immomig.as_a_tenant') }}
                  </v-card-title>
                  <v-card-text :class="[$vuetify.display.smAndUp ? 'px-0' : 'text-center pt-0']">
                    <p class="italic">
                      {{ $t('tayo_immomig.as_a_tenant_desc1') }}
                    </p>
                    <p class="paragraphs-spacing italic">
                      {{ $t('tayo_immomig.as_a_tenant_desc2') }}
                    </p>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-8" style="background-color: #FFFFFF">
        <v-col
          xs="12"
          sm="10"
          md="10"
          lg="8"
          xl="4"
          cols="12"
        >
          <v-row>
            <v-col
              v-if="$vuetify.display.xs"
              xs="12"
              sm="4"
              md="4"
              lg="3"
              cols="12"
            >
              <div class="d-flex justify-center">
                <v-img src="/img/tayoimmomig/pm.svg" height="150" width="220" />
              </div>
            </v-col>
            <v-col
              xs="12"
              sm="8"
              md="8"
              lg="9"
              cols="12"
            >
              <div xs12 md8 class="d-flex">
                <v-card flat tile>
                  <v-card-title :class="[$vuetify.display.smAndUp ? 'px-0' : 'text-center pt-0']">
                    {{ $t('tayo_immomig.as_a_pm_owner') }}
                  </v-card-title>
                  <v-card-text :class="[$vuetify.display.smAndUp ? 'px-0' : 'text-center pt-0']">
                    <p class="italic">
                      {{ $t('tayo_immomig.as_a_pm_owner_desc1') }}
                    </p>
                    <p class="paragraphs-spacing italic">
                      {{ $t('tayo_immomig.as_a_pm_owner_desc2') }}
                    </p>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
            <v-col
              v-if="$vuetify.display.smAndUp"
              xs="12"
              sm="4"
              md="4"
              lg="3"
              cols="12"
            >
              <v-img src="/img/tayoimmomig/pm.svg" height="200" width="220" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <v-row justify="center" class="mt-8" style="background-color: #FFFFFF">
        <v-col
          xs="12"
          sm="10"
          md="10"
          lg="8"
          xl="4"
          cols="12"
        >
          <v-row>
            <v-col
              xs="12"
              sm="4"
              md="4"
              lg="3"
              cols="12"
            >
              <div class="d-flex justify-center">
                <v-img src="/img/tayoimmomig/other.svg" :height="getProperSize('200', '150')" width="220" />
              </div>
            </v-col>
            <v-col
              xs="12"
              sm="8"
              md="8"
              lg="9"
              cols="12"
            >
              <div xs12 md8 class="d-flex">
                <v-card flat tile>
                  <v-card-text :class="[$vuetify.display.smAndUp ? 'px-0' : 'text-center pt-0', 'px-0 py-2']" style="line-height: 1.6rem; font-weight: 600; font-size: 20px;">
                    {{ $t('tayo_immomig.as_a_supplier_concierge_etc') }}
                  </v-card-text>
                  <v-card-text :class="[$vuetify.display.smAndUp ? 'px-0 pt-1' : 'text-center pt-1']">
                    <p class="italic">
                      {{ $t('tayo_immomig.as_a_supplier_concierge_etc_desc') }}
                    </p>
                  </v-card-text>
                </v-card>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row>
        <v-col>
          <div class="wave mt-8" style="margin: 0 auto" />
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col>
          <h3 class="text-center mt-6">
            {{ $t('tayo_immomig.workflow') }}
          </h3>
        </v-col>
      </v-row>
      <v-row justify="center" class="mt-4">
        <v-col
          xs="12"
          sm="8"
          md="8"
          lg="8"
          cols="12"
        >
          <div class="px-4">
            <v-img src="/img/tayoimmomig/cycle.svg" :height="getProperSize(300, 150)" />
          </div>
        </v-col>
      </v-row>
    </v-container>

    <!-- TIMELINE -->
    <v-container class="pt-12 mb-12">
      <v-row justify="center">
        <v-col
          xs="12"
          sm="12"
          md="10"
          lg="10"
          cols="12"
        >
          <div :class="[$vuetify.display.xs ? 'px-0' : 'px-12', 'timeline']">
            <div class="timeline__component">
              <div class="timeline__date--right mt-12">
                <span style="color: #99CC33;" class="number">
                  1.
                </span>
                {{ $t('tayo_immomig.step1') }}
              </div>
            </div>
            <div class="timeline__middle">
              <div class="timeline__point" />
            </div>
            <div class="timeline__component--bg mt-8" />
            <div class="timeline__component">
              <div class="timeline__date--right">
                <span style="color: #99CC33;" class="number">
                  2.
                </span>
                {{ $t('tayo_immomig.step2') }}
              </div>
            </div>
            <div class="timeline__middle" />
            <div class="timeline__component" />
            <div class="timeline__component">
              <div class="timeline__date--right">
                <span style="color: #99CC33;" class="number">
                  3.
                </span>
                {{ $t('tayo_immomig.step3') }}
              </div>
            </div>
            <div class="timeline__middle" />
            <div class="pl-3">
              {{ $t('tayo_immomig.actors_1') }}
            </div>
            <div class="timeline__component">
              <div class="timeline__date--right">
                <span style="color: #99CC33;" class="number">
                  4.
                </span>
                {{ $t('tayo_immomig.step4') }}
              </div>
            </div>
            <div class="timeline__middle" />
            <div class="timeline__component" />
            <div class="timeline__component">
              <div class="timeline__date--right">
                <span style="color: #99CC33;" class="number">
                  5.
                </span>
                {{ $t('tayo_immomig.step5') }}
              </div>
            </div>
            <div class="timeline__middle" />
            <div class="timeline__component" />
            <div class="timeline__component">
              <div class="timeline__date--right">
                <span style="color: #99CC33;" class="number">
                  6.
                </span>
                {{ $t('tayo_immomig.step6') }}
              </div>
            </div>
            <div class="timeline__middle" />
            <div :class="[$vuetify.display.smAndUp ? 'mt-8' : 'mt-16', 'timeline__component--bg ']" />
            <div class="timeline__component">
              <div class="timeline__date--right">
                <span style="color: #FFCC00;" class="number">
                  7.
                </span>
                {{ $t('tayo_immomig.step7') }}
              </div>
            </div>
            <div class="timeline__middle" />
            <div class="pl-3 mt-2" style="border-bottom: 1px solid #000000">
              {{ $t('tayo_immomig.actors_2') }}
            </div>
            <div class="timeline__component">
              <div class="timeline__date--right">
                <span style="color: #FFCC00;" class="number">
                  8.
                </span>
                {{ $t('tayo_immomig.step8') }}
              </div>
            </div>
            <div class="timeline__middle" />
            <div class="timeline__component" />
            <div class="timeline__component">
              <div class="timeline__date--right">
                <span style="color: #FFCC00;" class="number">
                  9.
                </span>
                {{ $t('tayo_immomig.step9') }}
              </div>
            </div>
            <div class="timeline__middle" />
            <div class="pl-3">
              {{ $t('tayo_immomig.actors_3') }}
            </div>
            <div class="timeline__component">
              <div class="timeline__date--right">
                <span style="color: #FFCC00;" class="number">
                  10.
                </span>
                {{ $t('tayo_immomig.step10') }}
              </div>
            </div>
            <div class="timeline__middle" />
            <div class="timeline__component" />
            <div class="timeline__component">
              <div class="timeline__date--right">
                <span style="color: #FFCC00;" class="number">
                  11.
                </span>
                {{ $t('tayo_immomig.step11') }}
              </div>
            </div>
            <div class="timeline__middle" />
            <div :class="[$vuetify.display.smAndUp ? 'mt-8' : 'mt-16', 'timeline__component--bg ']" />
            <div class="timeline__component">
              <div class="timeline__date--right">
                <span style="color: #FFCC00;" class="number">
                  12.
                </span>
                {{ $t('tayo_immomig.step12') }}
              </div>
            </div>
            <div class="timeline__middle" />
            <div class="pl-3">
              {{ $t('tayo_immomig.actors_2') }}
            </div>
            <div class="timeline__component" />
            <div class="timeline__middle">
              <div class="timeline__point timeline__point--bottom" />
            </div>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <div class="identity-gradient" style="height: 2px;" />
    <v-card flat tile class="d-flex justify-center mt-8">
      <div>
        <v-btn flat tile class="small-btn identity-gradient" style="color: white" @click="handleCLick('https://share.hsforms.com/1vv6t6ubvTA2zdjc7-8Begw30gqn')">
          {{ $t('tayo_immomig.contact_us') }}
        </v-btn>
      </div>
    </v-card>
    <v-card flat tile class="d-flex justify-center mt-4">
      <div>
        <v-btn flat tile class="small-btn mr-4" @click="handleCLick('https://tayo-software.com/' + locale + '/' + $t('tayo_immomig.url_newsletter') + '#anchorNewsletter')">
          Tayo newsletter
        </v-btn>
      </div>
      <div>
        <v-btn flat tile class="small-btn" @click="handleCLick('https://www.immomig.ch/de/blog#contact')">
          Immomig newsletter
        </v-btn>
      </div>
    </v-card>
    <v-card flat tile class="d-flex justify-center mt-4 mb-8">
      <div class="mr-4">
        <a href="https://tayo-software.com/fr" target="_blank" class="pt-2">
          <v-img
            src="/img/tayoimmomig/tayo-black.svg"
            max-width="72"
            width="132"
          /></a>
      </div>
      <div class="ml-4">
        <a href="https://www.immomig.ch/" target="_blank" class="pt-2">
          <v-img
            src="/img/tayoimmomig/IMMOMIGSA.svg"
            width="132"
          />
        </a>
      </div>
    </v-card>
  </div>
</template>
<script>
import i18n from '../../helpers/i18n.js'
export default {
  data () {
    return {
      locale: i18n.global.locale.value
    }
  },
  head () {
    return {
      title: i18n.global.t('meta_title.tayo_immomig'),
      meta: [
        { property: 'og:title', content: i18n.global.t('meta_title.tayo_immomig') },
        { property: 'og:image', content: 'https://tayo-software.com/img/tayoimmomig/TayoImmomig.png' },
        { property: 'og:description', content: i18n.global.t('meta_desc.tayo_immomig') },
        {
          hid: 'description',
          name: 'description',
          content: i18n.global.t('meta_desc.tayo_immomig')
        }
      ]
    }
  },
  methods: {
    goTo (url) {
      this.$router.go(url)
    },
    getProperSize (desktopSize, mobileSize) {
      if (this.$vuetify.display.smAndUp) {
        return desktopSize
      } else {
        return mobileSize
      }
    },
    handleCLick (url) {
      window.open(url)
    }
  }
}
</script>
<style scoped lang="scss">
.main {
    font-family: Helvetica, Arial, sans-serif;
    background-color: #FFFFFF
}
h1, h3, p {
    font-family: Helvetica, Arial, sans-serif;
}
.small-btn {
    font-size: .75rem;
}
.smaller-decription {
    font-size: 12px;
}
.v-card-title {
    font-weight: 600;
}
.paragraphs-spacing {
    margin-top: 12px;
}
h1 {
    color: black;
}
h3 {
    font-size: 22px;
    font-weight: normal;
}
.full-page-desktop {
    height: 100vh;
    background-image: url('/img/tayoimmomig/background.svg');
    background-size: cover;
    background-position: top;
}
.full-page-mobile {
    height: 100vh;
        background: linear-gradient(
    to top,
    #FFFFFF 0%,
    #FFFFFF 50%,
    #000000 50%,
    #000000 100%
  );
}
.white-text {
    color: white
}
.business-desc-text {
    font-size: 26px;
    line-height: 1.2em;
}
.business-desc-text-mobile {
    font-size: 20px;
    line-height: 1.2em;
}
.emoji::before {
    content: "\01F389";
}
.wave {
 display: block;
  width: 100px;
  height: 82px;
  background: url('/img/tayoimmomig/path.svg');
  background-size: 100px 82px;
}
.italic {
  font-style: italic;
}
.identity-gradient {
    background: linear-gradient(-45deg, #FFCC00, #BA2525, #000000);
    animation: tinyGradient 15s ease infinite;
}
.gradient-text {
    font-size: 22px;
    background: linear-gradient(-45deg, #FFCC00, #BA2525, #000000);
    -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
@keyframes tinyGradient {
0% { background-position: 0% 50%; }
50% { background-position: 100% 50%; }
100% { background-position: 0% 50%; }
}

.number {
   font-weight: 800;
}

.timeline {
  margin: 0 auto;
  max-width: 750px;
  display: grid;
  grid-template-columns: 1fr 3px 1fr;
}

.timeline__component {
  margin: 0 20px 20px 20px;
}

.timeline__component--bg {
    border-top: 1px solid black;
}

/* LEAVE TILL LAST */
.timeline__component--bottom {
  margin-bottom: 0;
}

.timeline__middle {
  position: relative;
  background: black;
}

.timeline__point {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 15px;
  height: 15px;
  background: #000000;
  border-radius: 50%;
}

/* LEAVE TILL LAST */
.timeline__point--bottom {
  top: initial;
  bottom: 0;
}

.timeline__date--right {
  text-align: right;
}

.timeline__title {
  margin: 0;
  font-size: 1.15em;
  font-weight: bold;
}

.timeline__paragraph {
  line-height: 1.5;
}

.image-as-button {
    cursor: pointer;
}
</style>
